module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.icthub.com.au"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"561450112263941"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"www.icthub.com.au","short_name":"ICTHUB","start_url":"/","background_color":"#141414","theme_color":"#141414","display":"minimal-ui","icon":"src/images/icthub-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"855b305748fc357588249f295f678716"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
