// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-acceptable-use-policy-js": () => import("./../../../src/pages/AcceptableUsePolicy.js" /* webpackChunkName: "component---src-pages-acceptable-use-policy-js" */),
  "component---src-pages-appointment-of-advocate-js": () => import("./../../../src/pages/AppointmentOfAdvocate.js" /* webpackChunkName: "component---src-pages-appointment-of-advocate-js" */),
  "component---src-pages-business-sip-js": () => import("./../../../src/pages/BusinessSip.js" /* webpackChunkName: "component---src-pages-business-sip-js" */),
  "component---src-pages-cloud-services-js": () => import("./../../../src/pages/cloud-services.js" /* webpackChunkName: "component---src-pages-cloud-services-js" */),
  "component---src-pages-collabration-js": () => import("./../../../src/pages/collabration.js" /* webpackChunkName: "component---src-pages-collabration-js" */),
  "component---src-pages-complaints-handling-policy-js": () => import("./../../../src/pages/complaints-handling-policy.js" /* webpackChunkName: "component---src-pages-complaints-handling-policy-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-critical-information-summary-js": () => import("./../../../src/pages/critical-information-summary.js" /* webpackChunkName: "component---src-pages-critical-information-summary-js" */),
  "component---src-pages-cyber-security-js": () => import("./../../../src/pages/cyber-security.js" /* webpackChunkName: "component---src-pages-cyber-security-js" */),
  "component---src-pages-digital-workplace-js": () => import("./../../../src/pages/digital-workplace.js" /* webpackChunkName: "component---src-pages-digital-workplace-js" */),
  "component---src-pages-direct-debit-js": () => import("./../../../src/pages/direct-debit.js" /* webpackChunkName: "component---src-pages-direct-debit-js" */),
  "component---src-pages-direct-debit-request-service-agreement-js": () => import("./../../../src/pages/direct-debit-request-service-agreement.js" /* webpackChunkName: "component---src-pages-direct-debit-request-service-agreement-js" */),
  "component---src-pages-disclosure-page-js": () => import("./../../../src/pages/disclosure-page.js" /* webpackChunkName: "component---src-pages-disclosure-page-js" */),
  "component---src-pages-fair-use-policy-js": () => import("./../../../src/pages/fair-use-policy.js" /* webpackChunkName: "component---src-pages-fair-use-policy-js" */),
  "component---src-pages-financial-hardship-policy-js": () => import("./../../../src/pages/financial-hardship-policy.js" /* webpackChunkName: "component---src-pages-financial-hardship-policy-js" */),
  "component---src-pages-hosted-extension-js": () => import("./../../../src/pages/HostedExtension.js" /* webpackChunkName: "component---src-pages-hosted-extension-js" */),
  "component---src-pages-important-documents-js": () => import("./../../../src/pages/important-documents.js" /* webpackChunkName: "component---src-pages-important-documents-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internet-and-data-js": () => import("./../../../src/pages/internet-and-data.js" /* webpackChunkName: "component---src-pages-internet-and-data-js" */),
  "component---src-pages-it-procurement-js": () => import("./../../../src/pages/it-procurement.js" /* webpackChunkName: "component---src-pages-it-procurement-js" */),
  "component---src-pages-key-fact-sheet-nbn-service-js": () => import("./../../../src/pages/key-fact-sheet-nbn-service.js" /* webpackChunkName: "component---src-pages-key-fact-sheet-nbn-service-js" */),
  "component---src-pages-managed-it-services-js": () => import("./../../../src/pages/managed-it-services.js" /* webpackChunkName: "component---src-pages-managed-it-services-js" */),
  "component---src-pages-managed-network-js": () => import("./../../../src/pages/managed-network.js" /* webpackChunkName: "component---src-pages-managed-network-js" */),
  "component---src-pages-microsoft-teams-js": () => import("./../../../src/pages/MicrosoftTeams.js" /* webpackChunkName: "component---src-pages-microsoft-teams-js" */),
  "component---src-pages-nbn-cis-js": () => import("./../../../src/pages/NbnCis.js" /* webpackChunkName: "component---src-pages-nbn-cis-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-return-policy-js": () => import("./../../../src/pages/return-policy.js" /* webpackChunkName: "component---src-pages-return-policy-js" */),
  "component---src-pages-standard-form-of-agreement-js": () => import("./../../../src/pages/standard-form-of-agreement.js" /* webpackChunkName: "component---src-pages-standard-form-of-agreement-js" */),
  "component---src-pages-voice-and-mobility-js": () => import("./../../../src/pages/voice-and-mobility.js" /* webpackChunkName: "component---src-pages-voice-and-mobility-js" */),
  "component---src-pages-website-disclaimer-js": () => import("./../../../src/pages/website-disclaimer.js" /* webpackChunkName: "component---src-pages-website-disclaimer-js" */),
  "component---src-pages-website-term-and-conditions-of-use-of-servoices-js": () => import("./../../../src/pages/website-term-and-conditions-of-use-of-servoices.js" /* webpackChunkName: "component---src-pages-website-term-and-conditions-of-use-of-servoices-js" */)
}

